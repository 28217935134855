@use "@els/els-styleguide-core/scss/core" as *;
@use "../../pages/catalog/catalog.component" as *;

.c-scm-course-plan-page {

  &__toggle_switch {
    color: $els-color-secondary;
    padding-right: $els-space;

    &-button {
      color: $els-color-secondary;
      display: block;
    }

    // TODO: Update slide switch component to take custom classes
    .c-els-slide-switch {
      display: block
    }

    .c-els-toggle-text {
      color: $els-color-n8;
      padding-left: $els-space-1o2
    }
  }

  &__default-tab {
    color: $els-color-n7
  }

  &__batch-button {
    color: white;
  }

  &__batch-controls {
    display: flex;
    background-color: $els-color-secondary;
    padding: 0 $els-space-2x;
    height: auto;
    max-height: 0;
    transition: $els-transition-duration-default max-height $els-cubic-bezier-primary;
    overflow-x: auto;
    overflow-y: hidden;

    .c-els-field__switch {
      background: white;
      border-color: $els-color-n3;
    }

    @include mq($until: mobile) {
      padding: 0 $els-space-1x;
    }

    &-container {
      min-height: $els-catalog-actions-height-large;

      @include mq($until: mobile) {
        min-height: $els-catalog-actions-height-small;
      }
    }
  }

  &__empty-bg {
    width: 100%;
  }
}
