@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-my-resources {
  padding: $els-space-2x;
  border-color: $els-color-n3;
  border-style: solid;
  border-width: $els-thin-keyline-width;

  &__ebook {
    margin-top: $els-base-space;
  }

  &__purchase-link {
    margin-top: $els-space-1o2;
  }
}
