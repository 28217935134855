@use "@els/els-styleguide-core/scss/core" as *;

$els-catalog-actions-height-large: $els-space * 5; //TODO: Inherit this from $els-header-height-large
$els-catalog-actions-height-small: $els-space * 3; //TODO: Inherit this from $els-header-height-small

.c-scm-catalog {

  &__filters {
    margin-bottom: $els-space-2x;
  }

  &__list-top-bar {
    padding: $els-space;
  }

  &__select-all {
    padding: $els-space;
  }

  &__item-list {
    border-left: $els-thin-keyline-width solid $els-color-n3;
  }

  &__item-subtitle {
    white-space: pre-line;
  }

  &__item {
    border-top: $els-thin-keyline-width solid $els-color-n3;
    border-bottom: $els-thin-keyline-width solid $els-color-n3;
    border-left: $els-thin-keyline-width solid $els-color-n3;
    border-right: $els-thin-keyline-width solid $els-color-n3;
    padding: $els-space-3o4 $els-space;

    &--folder {
      padding: 0;
    }

    &-btn {
      padding: $els-space-3o4 $els-space;
      width: 100%;
      display: block;
    }

    &-level {
      &-1 {
        margin-left: $els-space-2x;
      }

      &-2 {
        margin-left: $els-space-2x * 2;
      }

      &-3 {
        margin-left: $els-space-2x * 3;
      }

      &-4 {
        margin-left: $els-space-2x * 4;
      }
    }

    &--not-published {
      background-color: $els-color-extended-orange-0;
    }

    &--recently-published {
      background-color: $els-color-extended-blue-0;
    }
  }

  &__controls {
    background-color: $els-color-secondary;
    flex: 0 1 auto;
    padding: 0 $els-space-2x;
    max-height: 0;
    transition: $els-transition-duration-default max-height $els-cubic-bezier-primary;
    overflow-y: hidden;

    @include mq($until: mobile) {
      padding: 0 $els-space-1x;
    }

    &-container {
      min-height: $els-catalog-actions-height-large;

      @include mq($until: mobile) {
        min-height: $els-catalog-actions-height-small;
      }
    }

    &--visible {
      max-height: 100 * $els-space;
    }
  }

  &__resources {
    margin-bottom: 0 !important;
  }

  &__search-resources {
    align-self: flex-end;
  }
}
