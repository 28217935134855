@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-content-icon {
  border-radius: 100%;
  padding: $els-space-1o4;
  color: $els-color-n7;

  &--eaq {
    background-color: #88d4d6;
    padding-bottom: $els-space-none;
  }

  &--eab {
    background-color: #bbbcfe;
  }

  &--case-study {
    background-color: #e5c5ff;
  }

  &--custom-link {
    background-color: #acd2ff;
  }

  &--evolve {
    background-color: #fdf5a1;
  }

  &--ebook-reading {
    background-color: #d4d5ff;
  }

  &--group-activity {
    background-color: #f3dbff;
  }

  &--lesson {
    background-color: #c0f25d;
  }

  &--osmosis-video {
    background-color: #f8b0cd;
    padding-bottom: $els-space-none;
    height: $els-space-1x1o2;
  }

  &--ppt {
    background-color: #feb7b7;
  }

  &--shadow-health {
    background-color: #fee042;
  }

  &--simchart {
    background-color: #fda39f;
  }

  &--simulation {
    background-color: #ececff;
  }

  &--skill {
    background-color: #ace4e6;
  }

  &--interactive-review {
    background-color: #D7B0FF;
  }
}
