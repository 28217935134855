@mixin cw-vertical-flex-container() {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: column;
  display: flex;
  min-height: 0;
  height: 100%;
  width: 100%;
}
