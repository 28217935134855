@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-sidebar-box {
  padding: $els-space-1x1o2;
  border-color: $els-color-n3;
  border-style: solid;
  border-width: $els-thin-keyline-width;
  margin-bottom: $els-space-1x1o2;
}

@include mq($until: tablet) {
  .c-scm-sidebar-box {
    padding: $els-space-1x;
    overflow: hidden;
  }
}
