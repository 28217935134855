@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-course-builder-preview {
  border: $els-thin-keyline-width solid $els-color-n3;
  padding: $els-space-2x;
  overflow: auto;
  max-height: $els-space * 40;
  background-color: white;
}

.s-scm-list-scroller-active {
  .c-scm-course-builder-preview {
    overflow: hidden;
  }
}
