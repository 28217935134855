@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/settings" as *;
@use "../../assets/mixins" as *;

$c-scm-course-builder__graphic-background-image-url: 'els-tree.svg';
.c-scm-course-builder {
  @include cw-vertical-flex-container;
  height: 100%;

  &__container {
    padding: $els-space-2x 0;
    min-height: 0;
    overflow: auto;
    height: 100%;
  }

  &__controls {
    border-top: $els-thick-keyline-width solid $els-color-n2;
    padding: $els-space-2x 0;

    @include mq($until: mobile) {
      padding: $els-space-1x 0;
    }
  }

  &__inner {
    max-width: $cw-max-page-width;
    margin: 0 auto;
    padding: 0 $els-space-2x;
  }

  &__graphic {
    background-image: url($c-scm-course-builder__graphic-background-image-url);
    background-position: $els-space 0;
    background-repeat: no-repeat;
  }

  &__locked-notification {
    border: $els-thick-keyline-width solid $els-color-n3;
    padding: $els-space-1x 0;
    margin-left: 0;
  }

}
.u-scm-course-builder-preview-content-height {
  height: $els-space * 23;
}


.s-scm-list-scroller-active {
  .c-scm-course-builder__container {
    overflow: hidden;
  }
}
