@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/settings" as *;

$cw-osmosis-assignment-player-sidebar-width: 25vw;

.c-scm-osmosis-player {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;

  .c-els-page-header {
    border-bottom-color: $els-color-n3
  }

  &__header {
    flex: 0 1 auto;
  }

  &__title {
    display: block;
    width: 100%;
    max-width: $cw-max-page-width;
    margin: auto;
  }

  &__body {
    min-height: 0;
    background: $els-color-n1;
  }

  &__sidebar {
    width: 0;
    transition: width $els-transition-duration-default * 2 $els-cubic-bezier-primary;
    overflow-x: hidden;


    &--expanded {
      width: $cw-osmosis-assignment-player-sidebar-width;
    }

    &-content {
      padding: $els-space-2x $els-space-1x $els-space-2x $els-space-2x;
      overflow: auto;
      border-right: $els-thick-keyline-width solid $els-color-n3;
      width: $cw-osmosis-assignment-player-sidebar-width;

      &-mobile {
        display: none;

        p {
          margin-bottom: $els-space;
        }
      }
    }

    p {
      margin-bottom: $els-space;
    }
  }

  &__sidebar-controls {
    margin-right: $els-space-2x;
  }

  &__page {
    padding: $els-space-2x;
    overflow: scroll;
  }

  &__iframe {
    aspect-ratio: 16 / 9;
    max-width: $cw-max-page-width;
    width: 100%;
    margin: $els-space-2x auto;
    display: block;
    border: 0;
  }
}

@include mq($until: mobile) {
  .c-scm-osmosis-player {
    &__body {
      overflow: scroll;
    }

    &__page {
      width: 100%;
    }

    &__sidebar {
      width: 100%;

      &-content {
        width: 100%;
        display: none;

        &-mobile {
          display: block;
        }
      }


      &-controls {
        display: none;
      }
    }
  }
}
