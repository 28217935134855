@use "../../assets/mixins" as *;

.c-scm-course-builder-page {
  @include cw-vertical-flex-container;
  position: fixed;
  left: 0;
  top: 0;

  &__container {
    height: 100%;
    min-height: 0;
  }

}

