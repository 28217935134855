@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-student-past-due-assignments {
  &__header {
    background: $els-color-extended-orange-0;
    padding: $els-space;
    display: block;
    width: 100%;
    &-column {
    }
  }
}
