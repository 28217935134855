@use "@els/els-styleguide-core/scss/core" as *;

$bulk-edit-item-type-col-min-width: 10rem;
$bulk-edit-item-date-col-min-width: 18rem;

.c-scm-bulk-edit-settings {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;

  &__window-scroll {
    overflow: scroll;
    width: 100%;
    min-height: 0;
    max-height: 100%;
    padding: 0 $els-space * 3;
    flex: 1 1 auto;
  }

  &__edit-setting-item-header {
    margin: $els-space-2x 0;
  }

  &__actions {
    border-top: $els-thick-keyline-width solid $els-color-n2;
    background-color: $els-color-n0;
    display: flex;

    @include mq($until: mobile) {
      padding: 0 $els-space-1x;
    }
  }

  &__actions-container {
    min-height: $els-space * 5;
    padding: 0 $els-space-2x;
    width: 100%;
  }

  &__item-type {
    flex-basis: 10%;
    flex-shrink: 0;
    min-width: $bulk-edit-item-type-col-min-width;
  }

  &__item-date {
    flex-basis: $bulk-edit-item-date-col-min-width;
    flex-shrink: 0;
    @include mq($until: mobile) {
      flex-basis: 0;
    }
  }

  &__item-grading {
    flex-basis: 10%;
    flex-shrink: 0;
    min-width: $bulk-edit-item-type-col-min-width;
  }

  &__edit-setting-item {
    padding: $els-space;
    border-width: $els-thin-keyline-width;
    border-style: solid;
    border-color: $els-color-n2;
    margin-bottom: -($els-thin-keyline-width);
  }

  &__available-date,
  &__due-date,
  &__grading-type,
  &__grading-points {
    min-width: $els-space-1x * 36;
  }

  &__grading-type,
  &__grading-points {
    min-height: $els-space-1x * 6;
  }

  .c-els-field__message--error {
    max-width: fit-content !important;
  }
}
