@use "@els/els-styleguide-core/scss/core" as *;

$scm-list-scroller-indicator-height: $els-space * 3.75;

.c-scm-list-scroller {
  padding-right: $els-space-1x1o2;
  position: relative;

  &--all-rendered {
    padding-right: 0;
  }

  &__item {
    margin-left: 1rem;
  }

  &__list {
    overflow-y: auto;
    touch-action: auto;
    height: 90vh;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .c-scm-list-scroller--all-rendered & {
      height: auto;
    }

    &-end {
      padding: $els-space-2x;
      text-align: center;
      &-img {
        max-width: 90%;
        max-height: 50vh;
      }
    }
  }

  &__scroll-bar {
    background: $els-color-n1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $els-space-3o4;
    border-radius: $els-space-3o4;
    overflow: hidden;

    &-indicator {
      height: $scm-list-scroller-indicator-height;
      background: $els-color-n5;
      position: absolute;
      left: 0;
      right: 0;
      margin: -$scm-list-scroller-indicator-height/2 0;
      border-radius: $els-space-3o4;
    }

    .c-scm-list-scroller--all-rendered & {
      display: none;
    }

  }
}

@include mq($until: mobile) {
  .c-scm-list-scroller {
    &__list {
      height: 90vh;
    }
  }
}
