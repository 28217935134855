@use "@els/els-styleguide-core/scss/core" as *;
@use "@els/els-ui-common-react/module/assets/scss/common/utilities" as *;

$scm-syllabus-content-icon-column-width: $els-base-space * 8.3;
$scm-syllabus-duration-column-width: $els-base-space * 11.5;
$scm-syllabus-assignment-column-width: $els-base-space * 14;
$scm-syllabus-performance-report-column-width: $els-base-space * 7;
$nest-level-limit: 10;

@mixin create-level-modifier($max, $property) {
  @for $i from 2 to $max {
    &--level-#{$i} {
      #{$property}: #{$els-space-2x * ($i - 1)};

      .c-scm-syllabus-item__drag-icon {
        left: #{$els-space-2x * ($i - 1)};
      }
    }
  }
}

.c-scm-syllabus-item-list {
  overflow: hidden;
  padding-bottom: $els-thin-keyline-width;
  border-left: $els-thin-keyline-width solid $els-color-n2;
  border-top: $els-thin-keyline-width solid $els-color-n2;
  border-bottom: $els-thin-keyline-width solid $els-color-n2;
}

.c-scm-syllabus-item {
  position: relative;
  $this: &;
  padding-left: $els-space-2x;

  &__column {
    &--accordion-cursor {
      cursor: pointer;
    }
  }

  &--root {
    padding-left: 0;
  }

  @include create-level-modifier(
      $nest-level-limit,
      'padding-left'
  );

  &--folder {
    > #{$this}__heading {
      background-color: $els-color-n0;
      padding-top: $els-space-1o2;
      padding-bottom: $els-space-1o2;
    }
  }

  &--empty-folder-placeholder {
    #{$this}__heading {
      text-align: center;
    }
  }

  &__drag-surface {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;
    display: none;

    @include create-level-modifier(
        $nest-level-limit,
        'margin-left',
    );

    &--visible {
      display: block;
    }
  }

  &__drop-indicator {
    height: $els-space * 3;
    display: none;
    margin-top: $els-thin-keyline-width;

    @include create-level-modifier(
        $nest-level-limit,
        'padding-left',
    );

    &--visible {
      display: block;
    }
  }

  &__content-icon {
    color: $els-color-n7;
    margin-bottom: $els-space-1o2;
    font-size: $els-fs-meta;

    .c-scm-syllabus-item--highlight & {
      color: $els-color-n9;
    }
  }

  &__heading {
    border-color: $els-color-n3;
    border-style: solid;
    border-width: $els-thin-keyline-width;
    display: block;
    width: 100%;
    padding: $els-space-1x;
    color: $els-color-n9;
    margin-bottom: -($els-thin-keyline-width);
  }

  &__title {
    font-size: $els-fs-base;
  }

  &__subtitle {
    font-size: $els-fs-meta;
    white-space: pre-line;
  }

  &__drag-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -($els-space-3o4);
    margin-left: -($els-space-1x1o4);
  }

  &--drag-mode-on {
    cursor: move;
  }

  &--highlight {
    background: $els-color-extended-orange-0;
  }

  &--not-published &__heading {
    background-color: $els-color-extended-orange-0;
  }

  &--recently-published &__heading {
    background-color: $els-color-extended-blue-0;
  }

  &__student-progress {
    min-width: 7.1rem;
  }

}

@include mq($from: desktop) {
  .c-scm-syllabus-item {

    &__duration {
      min-width: $scm-syllabus-duration-column-width;
    }

    &__view-report {
      width: $scm-syllabus-performance-report-column-width;
    }

    &__assignment {
      width: $scm-syllabus-assignment-column-width;
    }

    &__assignment-status {
      width: $scm-syllabus-assignment-column-width;
    }
  }
}

@include mq($until: tablet) {
  .c-scm-syllabus-item {
    &__assignment,
    &__assignment-status {
      width: auto;
    }
  }
}

@include mq($until: tablet) {
  .c-scm-syllabus-item {
    &__column {
      width: 100%;
      margin-bottom: $els-space-1o2;
    }

    &--folder {
      .c-scm-syllabus-item__column {
        width: auto;
        margin-bottom: 0;
      }
    }
  }
}


