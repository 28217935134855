@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-filter-indicators {
  margin-bottom: $els-space;
  display: block;

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  &__indicator {
    font-size: $els-fs-meta;
    display: block;
    background: $els-color-extended-blue-0;
    border-radius: $els-space;
    padding: $els-space-1o2 $els-space-3o4;
    margin-bottom: $els-space-1o2;
    margin-right: $els-space-1o2;
    float: left;

    &:last-child {
      margin-right: 0;
    }
  }

  &__close-btn {
    color: $els-color-secondary;
  }
}
