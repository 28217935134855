@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-catalog-filters {
  padding: $els-space-2x;
  border-color: $els-color-n3;
  border-style: solid;
  border-width: $els-thin-keyline-width;
  &__section {
    margin-bottom: $els-space-1x1o2;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sub-description {
    font-size: $els-fs-meta;
  }
}
