@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/settings" as *;

.c-scm-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 1 auto;
  }

  &__body {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }

  &__footer {
    flex: 0 1 auto;
  }

  &__content-wrapper {
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }


  &__content-header {
    flex: 0 1 auto;
  }

  &__content-body {
    flex: 1 1 auto;
    display: flex;
    min-height: 0;
  }

  &__content {
    overflow: auto;
    padding: 2 * $els-base-space;
    scroll-behavior: smooth;
    width: 100%;

    &--no-smooth-scroll {
      scroll-behavior: auto;
    }

    &--has-sidebar {
      padding-left: $els-space-1x;
    }
  }

  &__content-inner {
    margin: 0 auto;
    max-width: $cw-max-page-width;
  }

  &__header-nav-link {
    color: $els-color-n8;
    border-bottom: 2px solid transparent;
    font-size: $els-fs-body-large;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: $els-color-primary;
      border-bottom-color: $els-color-primary;
    }
  }

  &__sidebar {
    padding: $els-space-2x $els-space-1x $els-space-2x $els-space-2x;
    overflow: auto;
    max-width: 20 * $els-space;
    min-width: 14 * $els-space;
    touch-action: auto;

    &-body {
      @extend .c-els-nav-group !optional;
      overflow: auto;
      height: 100%;
    }

    &-section {
      border-bottom: $els-thick-keyline-width solid $els-color-n0;
      padding: $els-space-1x1o2 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &-icon {
      display: inline-block;
    }
  }

  &__chatbot-link {
    position: fixed;
    bottom: $els-space-2x;
    right: $els-space-2x;
    width: $els-space-3x;
    height: $els-space-3x;
    border-radius: 100%;
    background-color: $els-color-secondary;
    color: white;

    &-text {
      color: $els-color-secondary;
      position: absolute;
      right: 100%;
      padding-right: $els-space;
      text-wrap: nowrap;
      line-height: $els-space;
      height: $els-space;
      top: 50%;
      margin-top: -$els-space-1o2;
    }

    &-icon {
      width: $els-space-1x1o2;
      height: $els-space-1x1o2;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: $els-space-1x1o2/2*-1 0 0 $els-space-1x1o2/2*-1;
    }
  }
}

.s-scm-list-scroller-active {
  .c-scm-page__content,
  .c-scm-page__body {
    overflow: hidden;
    overscroll-behavior: contain;
  }
}

@include mq($until: tablet) {
  .c-scm-page {
    &__sidebar {
      padding: $els-space-1x1o2 $els-space-1x $els-space-1x1o2 $els-space-1x;
      min-width: 17 * $els-space;
    }
  }
}

@include mq($until: mobile) {
  .c-scm-page {
    &__sidebar {
      max-width: 100%;
      border-right: none;
      border-bottom: $els-thick-keyline-width solid $els-color-n0;
      padding: $els-space-2x;
    }

    &__content {
      padding: $els-space-2x;
    }
  }
}
