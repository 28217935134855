@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-skill-player {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 1 auto;
  }

  &__body {
    width: 100%;
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }

  &__help-link {
    flex: 0 1 auto;
    background-color: $els-color-extended-blue-0;
    padding: $els-space-1o2 $els-space;
  }

  &__iframe {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }
}

