@use "@els/els-styleguide-core/scss/core" as *;

.c-cw-remove-modal-message-list {
  &__type-message-item {
    margin: $els-space-1o2 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
