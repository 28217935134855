@use "@els/els-styleguide-core/scss/core" as *;

.c-els-card {
  &--banner-published {
    background-color: $els-color-extended-blue-0;
    border-color: $els-color-extended-blue-2
  }

  &--banner-not-published {
    background-color: $els-color-extended-orange-0;
    border-color: $els-color-extended-orange-2
  }
}
