@use "@els/els-styleguide-core/scss/core" as *;

$alert-max-width--simulation: $els-space-1x * 40;

.c-scm-simulation-player-page {

  &__row {
    padding: 0 $els-space;
  }

  &__card {
    max-width: $els-breakpoint-tablet;
  }

  &__canvas {
    width: 100%;
    height: auto;
    display: inline-block;

    &--hidden {
      display: none;
    }
  }
}
